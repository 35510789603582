<template>
  <div>
    <b-card>
      <h3 class="text-center">Donor Darah RSUD Otista Soreang</h3>
      <p class="text-center">Periode 01-02-2024 s/d 30-04-2024</p>

      <h4 class="mt-4">Donor Darah</h4>
      <b-table
        sticky-header
        striped
        hover
        small
        :items="donorItems"
        :fields="donorFields"
      >
      </b-table>

      <h4 class="mt-4">Gagal Aftap</h4>
      <b-table
        sticky-header
        striped
        hover
        small
        :items="gagalItems"
        :fields="gagalFields"
      >
        <!-- <template v-slot:cell(no)="data">
          {{ data.index + 1 }}
        </template> -->
      </b-table>
    </b-card>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { BCard, BTable } from "bootstrap-vue";

const donorFields = ref([
  { key: "no", label: "No", sortable: true },
  { key: "tanggal", label: "Tanggal", sortable: true },
  { key: "nama", label: "Nama", sortable: true },
  { key: "telepon", label: "Telepon", sortable: true },
  { key: "alamat", label: "Alamat", sortable: true },
  { key: "tanggalLahir", label: "Tanggal Lahir", sortable: true },
  { key: "jenisKelamin", label: "Jenis Kelamin", sortable: true },
  { key: "golonganDarah", label: "Gol. Darah", sortable: true },
]);

const donorItems = ref([
  {
    no: 1,
    tanggal: "2024-06-27",
    nama: "John Doe",
    telepon: "123-456-7890",
    alamat: "Jl. Contoh No. 123",
    tanggalLahir: "1990-01-01",
    jenisKelamin: "Laki-laki",
    golonganDarah: "A",
  },
  {
    no: 2,
    tanggal: "2024-06-28",
    nama: "Jane Smith",
    telepon: "987-654-3210",
    alamat: "Jl. Percobaan No. 456",
    tanggalLahir: "1992-02-02",
    jenisKelamin: "Perempuan",
    golonganDarah: "B",
  },
  {
    no: 3,
    tanggal: "2024-06-29",
    nama: "Bob Johnson",
    telepon: "555-555-5555",
    alamat: "Jl. Contoh No. 789",
    tanggalLahir: "1985-03-03",
    jenisKelamin: "Laki-laki",
    golonganDarah: "AB",
  },
]);

const gagalFields = ref([
  { key: "no", label: "No", sortable: true },
  { key: "tanggal", label: "Tanggal", sortable: true },
  { key: "nama", label: "Nama", sortable: true },
  { key: "telepon", label: "Telepon", sortable: true },
  { key: "alamat", label: "Alamat", sortable: true },
  { key: "tanggalLahir", label: "Tanggal Lahir", sortable: true },
  { key: "jenisKelamin", label: "Jenis Kelamin", sortable: true },
  { key: "golonganDarah", label: "Gol. Darah", sortable: true },
  { key: "alasanGagal", label: "Alasan Gagal", sortable: true },
  { key: "cc", label: "CC", sortable: true },
]);

const gagalItems = ref([
  {
    no: 1,
    tanggal: "2024-06-27",
    nama: "Alice Brown",
    telepon: "111-222-3333",
    alamat: "Jl. Contoh No. 321",
    tanggalLahir: "1993-04-04",
    jenisKelamin: "Perempuan",
    golonganDarah: "O",
    alasanGagal: "Tekanan darah rendah",
    cc: "250",
  },
  {
    no: 2,
    tanggal: "2024-06-28",
    nama: "Charlie Davis",
    telepon: "444-555-6666",
    alamat: "Jl. Contoh No. 654",
    tanggalLahir: "1988-05-05",
    jenisKelamin: "Laki-laki",
    golonganDarah: "B",
    alasanGagal: "Anemia",
    cc: "300",
  },
  {
    no: 3,
    tanggal: "2024-06-29",
    nama: "Eva Green",
    telepon: "777-888-9999",
    alamat: "Jl. Contoh No. 987",
    tanggalLahir: "1995-06-06",
    jenisKelamin: "Perempuan",
    golonganDarah: "A",
    alasanGagal: "Hemoglobin rendah",
    cc: "200",
  },
]);
</script>
